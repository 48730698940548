import { gsap, ScrollTrigger } from '../esm/all';

export default function gsapScrollToHash() {
    function goToHash(a) {
        const linkName = a.split('#')
            .pop();

        if (!linkName) return;

        const ele = document.querySelector(`#${linkName}`);
        if (!ele) return;

        const s_offset = ele.offsetTop;
        const s_height = ele.offsetHeight;
        const s_length = document.querySelectorAll('.section');
        const s_nodes = Array.from(s_length);
        const s_count = s_nodes.indexOf(ele);

        gsap.to(window, { scrollTo: s_offset + s_height * (s_count) });
    }

    function refreshScroll() {
        document.querySelector('#app').scrollTop = 0;
        window.scrollTo(0, 0);
        ScrollTrigger.refresh();
        goToHash(window.location.hash);
    }

    window.addEventListener('load', refreshScroll);
}
