import { gsap } from './esm/all';
import { ScrollTrigger } from './esm/ScrollTrigger';
import { ScrollToPlugin } from './esm/ScrollToPlugin';
import gsapScrollToHash from './extend/gsap-scroll-to-hash';
import gsapProgressbar from './extend/gsap-progressbar';
import gsapMenuNav from './extend/gsap-menu-nav';

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

const sections = gsap.utils.toArray('.section');
let currentSection = sections[0];

gsap.defaults({
    overwrite: 'auto',
    duration: 1,
    ease: 'power3'
});
gsap.set('body', { height: (sections.length * 100) + '%' });

sections.forEach((section, i) => {
    ScrollTrigger.create({
        // use dynamic scroll positions based on the window height (offset by half to make it feel natural)
        start: () => (i - 0.5) * innerHeight,
        end: () => (i + 0.5) * innerHeight,
        scrub: true,
        // markers: true,
        // pin: true,
        // anticipatePin: 1,
        toggleActions: 'play reset play reset',
        toggleClass: {
            targets: section,
            className: 'is-active'
        },
        // when a new section activates (from either direction), set the section accordingly.
        onToggle: (self) => self.isActive && setSection(section)
        // onEnter: () => section.classList.add('is-active'),
        // onReverseComplete: () => section.classList.remove('is-active'),
    });
});

function setSection(newSection) {
    if (newSection !== currentSection) {
        if (currentSection.querySelector('.g-in')) {
            gsap.timeline()
                .to(currentSection.querySelectorAll('.g-in'), {
                    y: -30,
                    autoAlpha: 0,
                    duration: 0.3
                });
        }

        gsap.timeline()
            .to(currentSection, {
                autoAlpha: 0,
                duration: 0.5
            });

        gsap.timeline()
            .to(newSection, {
                autoAlpha: 1,
                duration: 0.5
            });
        if (newSection.querySelector('.g-in')) {
            gsap.timeline()
                .fromTo(newSection.querySelectorAll('.g-in'), {
                    y: 30,
                    autoAlpha: 0
                }, {
                    autoAlpha: 1,
                    y: 0,
                    duration: 0.3
                });
        }

        currentSection = newSection;
    }
}

// Intro Animations
function introTimeline() {
    const tl = gsap.timeline({ defaults: { duration: 1 } });
    ScrollTrigger.matchMedia({
        // large
        '(min-width: 960px)': function () {
            tl
                .fromTo('.cls-2', {
                    x: -106,
                    fill: 'transparent',
                    opacity: 0
                }, {
                    delay: 1,
                    x: 0,
                    fill: '#fff',
                    opacity: 1
                })
                .fromTo('.cls-1', { x: 170 }, { x: 0 }, '-=1')
                .fromTo('.cls-3', {
                    yPercent: 100,
                    fill: 'transparent',
                    opacity: 0
                }, {
                    yPercent: 0,
                    fill: '#2DB4A7',
                    opacity: 1
                })
                .to('.box__text-title h1', {
                    opacity: 1,
                    scale: 1
                }, '-=1')
                .to('.box__text-title p', {
                    opacity: 1,
                    scale: 1
                }, '-=0.5')
                .fromTo('.clutch-reward', {
                    opacity: 0,
                    scale: 0.1
                }, {
                    opacity: 1,
                    scale: 1,
                    ease: 'bounce'
                }, '-=1')
                .fromTo('.sidebar .open-menu', { right: '-100%' }, {
                    right: '25%',
                    ease: 'power2.in'
                }, '-=1')
                .to('.w-scroll-down', {
                    opacity: 1,
                    scale: 1
                }, '-=1')
                .to('.sidebar .open-menu', {
                    right: '135%',
                    ease: 'power2.in'
                }, '-=0.5')
                .to('.logo', {
                    position: 'fixed',
                    width: '80px',
                    height: '50px',
                    top: '32px',
                    left: '120px',
                    pointerEvents: 'all'
                })
                .to('.sidebar .open-menu', {
                    right: '25%',
                    ease: 'power2.in'
                }, '-=1');
        },

        // medium
        '(min-width: 600px) and (max-width: 959px)': function () {
            // The ScrollTriggers created inside these functions are segregated and get
            // reverted/killed when the media query doesn't match anymore.
        },

        // small
        '(max-width: 767px)': function () {
            tl
                .fromTo('.cls-2', {
                    x: -106,
                    fill: 'transparent',
                    opacity: 0
                }, {
                    delay: 1,
                    x: 0,
                    fill: '#fff',
                    opacity: 1
                })
                .fromTo('.cls-1', { x: 170 }, { x: 0 }, '-=1')
                .fromTo('.cls-3', {
                    yPercent: 100,
                    fill: 'transparent',
                    opacity: 0
                }, {
                    yPercent: 0,
                    fill: '#2DB4A7',
                    opacity: 1
                })
                .to('.logo', {
                    position: 'fixed',
                    width: '80px',
                    height: '50px',
                    top: '10px',
                    left: '50%',
                    xPercent: '-50'
                })
                .to('.box__text-title h1', {
                    opacity: 1,
                    scale: 1
                }, '-=1')
                .to('.box__text-title p', {
                    opacity: 1,
                    scale: 1
                }, '-=0.5')

                .to('.w-scroll-down', {
                    opacity: 1,
                    scale: 1
                }, '-=1');
        },

        // all
        all() {
        }
    });
    return tl;
}

gsap.timeline()
    .add(introTimeline());

gsapMenuNav();
gsapProgressbar();
gsapScrollToHash();
