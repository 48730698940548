import { gsap } from '../esm/all';
// Navigation Scroll To Href

export default function gsapMenuNav() {
    gsap.utils.toArray('#menu a')
        .forEach((a, i) => {
            a.clickElem = document.querySelector(a.hash);
            a.offset = a.clickElem.offsetTop;
            a.height = a.clickElem.offsetHeight;
            a.addEventListener('click', (e) => {
                e.preventDefault();
                gsap.to(window, { scrollTo: a.offset + a.height * (i) });
                window.location.hash = a.hash;
            });
        });
}
